import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { CompatRouter } from 'react-router-dom-v5-compat'
import CssBaseline from '@mui/material/CssBaseline'
import ErrorBoundary from '@straetus/react/components/error-boundary'
import FullScreenLoader from '@straetus/react/components/full-screen-loader'
import AnalyticsProvider, { analyticsClient } from '@straetus/react/modules/analytics'
import EnvironmentProvider from '@straetus/react/modules/environment'
import ApolloProvider from '@straetus/react/modules/graphql'
import LanguageProvider from '@straetus/react/modules/language'
import SnackbarProvider from '@straetus/react/modules/snackbar'
import ThemeProvider from '@straetus/react/theme'
import posthog from 'posthog-js'

import type { Locale } from '@straetus/react/modules/language'

import packageJson from '../package.json'
import { environment } from './environments'
import { apolloLink } from './modules/apollo.link'
import { loadMessages } from './modules/languages.utils'
import Routes from './routes'
import supportedLocales from './translations/locales.json'

const container = document.getElementById('root')
const root = ReactDOM.createRoot(container)

if (process.env.NODE_ENV === 'production') {
  posthog.init(
    'phc_r1yki37u0jxPU87xCnstDa5yAONfV5i4ytnaBS9B1Kn',
    {
      api_host: 'https://eu.posthog.com',
      capture_pageview: false, // Disable automatic pageview capture, as we capture manually
      disable_session_recording: true,
      persistence: 'localStorage',

      loaded: () => {
        analyticsClient.lazySetPersonProperties({
          app_version: packageJson.version
        })
      }
    }
  )
}

root.render(
  <Router>
    <CompatRouter>
      <React.StrictMode>
        <EnvironmentProvider environment={environment}>
          <ThemeProvider>
            <React.Suspense fallback={<FullScreenLoader withDelay />}>
              <AnalyticsProvider>
                <CssBaseline />

                <LanguageProvider
                  loadMessages={loadMessages}
                  supportedLocales={supportedLocales as Locale[]}>
                  <ErrorBoundary
                    apiKey={environment.errorReportingApiKey}
                    projectId={'debtor-portal-app'}
                    service={packageJson.name}
                    version={packageJson.version}>
                    <ApolloProvider link={apolloLink}>
                      <SnackbarProvider>
                        <Routes />
                      </SnackbarProvider>
                    </ApolloProvider>
                  </ErrorBoundary>
                </LanguageProvider>
              </AnalyticsProvider>
            </React.Suspense>
          </ThemeProvider>
        </EnvironmentProvider>
      </React.StrictMode>
    </CompatRouter>
  </Router>
)
